import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  ARTELINK_PROJECT,
  EDF_PROJECT,
  LAFRAISE_PROJECT,
  GENIE_VISION_PROJECT,
  NAVYS_PROJECT,
  APV_PROJECT,
  SETEC_PROJECT,
  LMSG_PROJECT,
  MM2_PROJECT,
  HARMONIELLES_PROJECT,
  LAPOSTE_PROJECT,
} from '@/referentials/routes';

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      artelink: file(relativePath: { eq: "projects/artelink.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      edf: file(relativePath: { eq: "projects/edf.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      setec: file(relativePath: { eq: "projects/setec.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      lmsg: file(relativePath: { eq: "projects/lmsg.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      navys: file(relativePath: { eq: "projects/navys.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      laFraise: file(relativePath: { eq: "projects/la-fraise.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      genieVision: file(relativePath: { eq: "projects/genievision.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      apv: file(relativePath: { eq: "projects/apv.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      harmonielles: file(relativePath: { eq: "projects/harmonielles.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      mm2: file(relativePath: { eq: "projects/mm2.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      laPoste: file(relativePath: { eq: "projects/la-poste.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const projects = [
    {
      title: 'Groupe La Poste',
      link: LAPOSTE_PROJECT,
      picture: data.laPoste,
      tags: ['UX/UI', 'Development'],
    },
    {
      title: 'Artelink',
      link: ARTELINK_PROJECT,
      picture: data.artelink,
      tags: ['UX/UI', 'Development', 'Growth-hacking', 'Data Science'],
    },
    {
      title: 'EDF',
      link: EDF_PROJECT,
      picture: data.edf,
      tags: ['UX/UI', 'Development'],
    },
    {
      title: 'Setec',
      link: SETEC_PROJECT,
      picture: data.setec,
      tags: ['UX/UI'],
    },
    {
      title: 'LMSG',
      link: LMSG_PROJECT,
      picture: data.lmsg,
      tags: ['UX/UI', 'Development', 'Growth-hacking', 'Data Science'],
    },
    {
      title: 'La fraise',
      link: LAFRAISE_PROJECT,
      picture: data.laFraise,
      tags: ['UX/UI', 'Development', 'Growth-hacking', 'Data Science'],
    },
    {
      title: 'Navys',
      link: NAVYS_PROJECT,
      picture: data.navys,
      tags: ['Data Science'],
    },
    {
      title: 'APV',
      link: APV_PROJECT,
      picture: data.apv,
      tags: ['UX/UI', 'Development'],
    },
    {
      title: 'GenieVision',
      link: GENIE_VISION_PROJECT,
      picture: data.genieVision,
      tags: ['Growth-hacking'],
    },
    {
      title: 'MM2',
      link: MM2_PROJECT,
      picture: data.mm2,
      tags: ['UX/UI', 'Development'],
    },
    {
      title: 'Harmonielles',
      link: HARMONIELLES_PROJECT,
      picture: data.harmonielles,
      tags: ['Data Science'],
    },
  ];

  return (
    <div className="flex flex-wrap justify-between mb-[100px] md:mt-[150px] md:mb-[400px]">
      {projects.map((p, i) => {
        const rightColumn = i % 2 === 1;

        return (
          <div key={i} className="relative w-full md:w-[49%] mb-[40px]">
            <Link
              to={p.link}
              className={`
                rounded-main transition block hover:-translate-y-[2%] hover:shadow-primary
                ${rightColumn ? 'md:absolute md:top-[264px]' : ''}
              `}
              style={{ zIndex: rightColumn ? projects.length - i : 0 }} // To have bottom part of right column cards hoverable
            >
              <GatsbyImage
                image={getImage(p.picture)}
                alt={p.title}
                className="w-full rounded-main"
              />
              <div className="absolute flex bottom-4 xl:bottom-10 left-8 md:max-w-[80%] flex-wrap">
                {p?.tags.map((tag) => (
                  <div className="bg-white text-black whitespace-nowrap py-1 px-2 mr-2 mb-2 rounded-md w-fit text-xs md:text-2xs lg:text-base shadow-tag">
                    {tag}
                  </div>
                ))}
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
