import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { METHODOLOGIE } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHero from '@/components/Portfolio/PortfolioHero';
import CtaBlock from '@/components/CtaBlock';
import Projects from '@/components/Portfolio/Projects';

export default function Portfolio({ data }) {
  const { t } = useTranslation();

  return (
    <Layout
      title="Portfolio"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
    >
      <PortfolioHero />
      <Projects />
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={METHODOLOGIE}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.magic),
          alt: 'magic icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["portfolio", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    magic: file(relativePath: { eq: "magic.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
